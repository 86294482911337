<template>
  <div class="main">
    <newheadndl />
    <div class="ny-cons">
      <div class="ny-con">
        <div class="login-title">
          <div class="login-title-list">重置密码</div>
        </div>
        <div class="login-srk">
          <el-form
            :label-position="'top'"
            label-width="200px"
            :model="form"
            :rules="baseRules"
            ref="ruleFormRef"
          >
            <el-form-item label="手机号" required prop="username">
              <el-input
                v-model="form.username"
                placeholder="请输入手机号"
                @keyup.enter="onSubmit(ruleFormRef)"
              />
            </el-form-item>
            <el-form-item label="验证码" required prop="code">
              <el-input
                v-model="form.code"
                style="max-width: 600px"
                placeholder="请输入验证码"
                @keyup.enter="onSubmit(ruleFormRef)"
              >
                <template #append>
                  <el-button @click="getcode">获取验证码</el-button>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="新密码" required prop="password">
              <el-input
                v-model="form.password"
                show-password
                type="password"
                placeholder="请设置新密码"
                @keyup.enter="onSubmit(ruleFormRef)"
              />
            </el-form-item>
            <div class="login-srk-wjmm">
              <div class="login-srk-wjmm-link">
                <router-link to="/login">去登录</router-link>
              </div>
            </div>
            <div class="login-srk-btns">
              <el-button
                type="primary"
                size="large"
                @click="onSubmit(ruleFormRef)"
                >立即重置</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <newfooter />
  </div>
</template>

<script setup>
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

let form = reactive({
  username: "",
  password: "",
  code: "",
});
const ruleFormRef = ref();
const baseRules = {
  username: [{ required: true, message: "手机号不能为空", trigger: "blur" }],
  password: [{ required: true, message: "密码不能为空", trigger: "blur" }],
  code: [{ required: true, message: "验证码不能为空", trigger: "blur" }],
};
// 获取验证码
const getcode = () => {
  get("/api/UserLogin/SendPhoneCode", {
    p:form.username,
  }).then((res) => {
    ElMessage({
      type: "success",
      message: res.msg,
      duration: 1000,
    });
  });
};

// 提交
const onSubmit = (formEl) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    // 通过验证规则 true
    if (valid) {
      post("/api/UserLogin/ResetPass", form).then((res) => {
        ElMessage({
          type: "success",
          message: "重置成功",
          duration: 1000,
          onClose: () => {
            router.push({
              path: "/login",
              query: {},
            });
          },
        });
      });
    }
  });
};
</script>

<style scoped>
:deep(.el-form-item__content) {
  margin-left: 0 !important;
}
:deep(.login-srk-btns .el-button) {
  width: 100%;
}
:deep(.el-form) {
  padding: 50px 0;
}
:deep(.el-form-item__label) {
  font-weight: bold;
}
.main {
  background: #ebedf3;
  height: 100%;
}
.ny-nav {
  width: 1200px;
  height: 60px;
  margin: auto;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #888;
}
.ny-nav a:link,
.ny-nav a:visited {
  color: #888;
  text-decoration: none;
}
.ny-nav a:hover {
  color: #2489f2;
}
.ny-cons{
  width: 100%;
  height: calc(100% - 100px);
  min-height: 600px;
  display: flex;
  align-items: center;
}
.ny-con {
  width: 1200px;
  height: auto;
  background: #fff;
  box-shadow: 0 0 5px #ccc;
  border: 1px solid #dddfe5;
  border-radius: 2px;
  padding: 30px;
  margin: auto;
  box-sizing: border-box;
  margin-top: 100px;
}
.login-title {
  height: 50px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #e3e3e3;
}
.login-title-list {
  padding: 0 20px;
  height: 48px;
  line-height: 48px;
  border-bottom: 2px solid #2489f2;
}
.login-srk {
  width: 500px;
  height: auto;
  margin: auto;
}
.login-srk-title {
  font-weight: bold;
  padding: 0 0 10px 0;
}
.login-srk-wjmm {
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
  padding: 10px 0 20px 0;
}
.login-srk-wjmm-link a:link,
.login-srk-wjmm-link a:visited {
  color: #2489f2;
  text-decoration: none;
}
.login-srk-wjmm-link a:hover {
  text-decoration: underline;
}
</style>
